export interface MenuOption {
  label: string;
  external: boolean;
  disabled: boolean;
  handler: Function;
}

export interface HeaderLink {
  menuOptions: MenuOption[];
  showLink: boolean;
}

export enum HeaderLinkLabelEnum {
  allDeals = 'i18n.deals.allDeals',
  ba = 'i18n.moxe-links.ba',
  betterBuys = 'i18n.moxe-links.betterBuys',
  browseCategories = 'i18n.moxe-links.browseCategories',
  guestBrowseProducts = 'i18n.moxe-links.guestBrowseProducts',
  check = 'i18n.our-exclusives.check',
  dealsForYou = 'i18n.moxe-links.dealsForYou',
  farmersReport = 'i18n.moxe-links.farmerReport',
  premierMemberFoodForum = 'i18n.moxe-links.premierMemberFoodForum',
  gl = 'i18n.moxe-links.gl',
  inventory = 'i18n.moxe-links.inventory',
  mySavings = 'i18n.moxe-links.mySavings',
  invoicesAndPayments = 'i18n.moxe-links.invoicesAndPayments',
  mlm = 'i18n.moxe-links.mlm',
  mpp = 'i18n.moxe-links.mpp',
  moxeMpp = 'i18n.moxe-links.moxeMpp',
  supplies = 'i18n.moxe-links.supplies',
  scoop = 'i18n.moxe-links.scoop',
  equipmentAndSupplies = 'i18n.moxe-links.equipmentAndSupplies',
  serveGood = 'i18n.our-exclusives.serveGood',
  serveYou = 'i18n.moxe-links.serveYou',
  recipes = 'i18n.our-exclusives.recipes',
  myOrders = 'i18n.moxe-links.myOrders',
  orderStatus = 'i18n.moxe-links.orderStatus',
  importOrder = 'i18n.importOrderModal.importOrder',
  importedOrders = 'i18n.importOrderPage.importedOrders',
  tv360 = 'i18n.moxe-links.tv360',
  quickOrderEntry = 'i18n.moxe-links.quickOrderEntry',
  imageOrdering = 'i18n.moxe-links.imageOrdering',
}
