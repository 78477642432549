import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Customer, CustomerSummary } from '@usf/customer-types/customer';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { CUSTOMER_ACTIONS } from '../../ngrx-customer/store';
import { CustomerKey } from '@usf/customer-types';
import { UserService } from '@app/user/services';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  isSuperUserCustomersModalOpen$ = new BehaviorSubject<boolean>(false);
  showCustomersDataLoadingSpinner$ = new BehaviorSubject<boolean>(false);
  filteredCustomerSummaries$ = new BehaviorSubject<CustomerSummary[]>([]);

  retrievedCustomer$ = new BehaviorSubject<Customer>({} as Customer);

  private initialFavoritesSubject = new BehaviorSubject<CustomerSummary[]>([]);
  private initialFavoritesObservable =
    this.initialFavoritesSubject.asObservable();

  constructor(
    private store: Store,
    private userService: UserService,
  ) {}

  searchCustomers(searchKey: string) {
    this.store.dispatch(
      CUSTOMER_ACTIONS.searchCustomers({
        searchKey,
      }),
    );
  }

  setIsSuperUserCustomersModalOpen(isOpen: boolean) {
    this.isSuperUserCustomersModalOpen$.next(isOpen);
  }

  setShowCustomersDataLoadingSpinner(isOpen: boolean) {
    this.showCustomersDataLoadingSpinner$.next(isOpen);
  }

  setFilteredCustomerSummaries(customerSummaries: CustomerSummary[]) {
    this.filteredCustomerSummaries$.next(customerSummaries);
  }

  setRetrievedCustomer(customer: any) {
    this.retrievedCustomer$.next(customer);
  }
  setFavorites(customerSummaries: CustomerSummary[]): void {
    this.initialFavoritesSubject.next(customerSummaries);
  }

  getRetrievedCustomer$(): Observable<Customer> {
    return this.retrievedCustomer$.asObservable();
  }

  getFilteredCustomerSummaries$(): Observable<CustomerSummary[]> {
    return this.filteredCustomerSummaries$.asObservable();
  }

  getCustomersDataLoadingSpinner$(): Observable<boolean> {
    return this.showCustomersDataLoadingSpinner$.asObservable();
  }
  getFavorites$(): Observable<CustomerSummary[]> {
    return this.initialFavoritesObservable;
  }

  retrieveCustomerFavorites(): void {
    this.userService
      .getUserPreferencesV2()
      .pipe(take(1))
      .subscribe(preferences => {
        if (preferences?.favoriteCustomers?.length > 0) {
          this.store.dispatch(
            CUSTOMER_ACTIONS.retrieveCustomerSummariesByKey({
              customerKeys: preferences.favoriteCustomers,
            }),
          );
        } else {
          this.setShowCustomersDataLoadingSpinner(false);
        }
      });
  }
}
