import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Customizations, PanAppState, UserState } from '@panamax/app-state';
import { OrderItemStoreService } from '@usf/ngrx-order';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BetterBuysService } from '@app/better-buys/services/better-buys.service';
import { DealsTracingService } from '@app/deals/services/deals-tracing.service';
import { DealsService } from '@app/deals/services/deals.service';
import { OrderService } from '@order/services/order.service';
import { SearchTracingService } from '@search/services/search-tracing.service';
import { UserService } from '@app/user/services';
import { CustomizationOptions } from '../../constants/customizations.enum';
import {
  HeaderLink,
  HeaderLinkLabelEnum,
  MenuOption,
} from '../../constants/header';
import { PATHS } from '../../constants/paths';
import { SEARCH_PAGES_SEARCH_KEYS } from '../../constants/search-pages.enum';
import { FEATURES } from '../../constants/splitio-features';
import { NavigationHelperService } from '../../helpers/navigation.helpers.service';
import { PopoverHelperService } from '../../helpers/popover.helpers';
import { AppService } from '../../services/app.service';
import { ClaimTicketService } from '../../services/claim-ticket.service';
import { ErrorPopoverComponent } from './error-popover/error-popover.component';
import { HeaderViewModel } from './header-view.model';
import { ProductAnalyticsService } from '@usf/ngrx-product';
import { TopSectionSearchText } from '@search/shared/models/search-page.model';
import { Store } from '@ngrx/store';
import { selectCustomer } from '@panamax/app-state';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(
    private orderItemStoreService: OrderItemStoreService,
    private orderService: OrderService,
    private betterBuysService: BetterBuysService,
    private panAppState: PanAppState,
    private userService: UserService,
    private dealsService: DealsService,
    private navigationHelperService: NavigationHelperService,
    private popoverController: PopoverController,
    private dealsTracingService: DealsTracingService,
    private appService: AppService,
    private claimTicketService: ClaimTicketService,
    private popoverHelperService: PopoverHelperService,
    private translateService: TranslateService,
    private searchTracingService: SearchTracingService,
    private analyticsService: ProductAnalyticsService,
    private store: Store,
  ) {}

  getHeaderViewModel(): Observable<HeaderViewModel> {
    const cartSyncing$ = this.orderService.isCartSyncingIpOrder$();
    const orderItemTallies$ = this.orderItemStoreService.loadOrderItemTallies();
    const myBusinessLink$ = this.getMyBusinessLink$();
    const productAndDealsLink$ = this.getProductsAndDealsLink$();
    const myOrdersLink$ = this.getMyOrderLinks$();
    const manageCustomizationsLogo$ = this.panAppState.feature$([
      FEATURES.split_global_user_customizations,
    ]);
    return combineLatest([
      cartSyncing$,
      orderItemTallies$,
      myBusinessLink$,
      productAndDealsLink$,
      myOrdersLink$,
      manageCustomizationsLogo$,
    ]).pipe(
      map(
        ([
          cartSyncing,
          orderItemTallies,
          myBusinessLink,
          productAndDealsLink,
          myOrdersLink,
          manageCustomizationsLogo,
        ]) => {
          const viewModel: HeaderViewModel = {
            cartSyncing,
            orderItemTallies,
            myBusinessLink,
            myOrdersLink,
            productAndDealsLink,
            manageCustomizationsLogo,
          };
          return viewModel;
        },
      ),
    );
  }

  getMyOrderLinks$(): Observable<HeaderLink> {
    const myOrdersMenuOption = this.getMyOrdersMenuOption();
    const orderStatusMenuOption$ = this.getOrderStatusMenuOption$();
    const importOrderMenuOption$ = this.getImportOrderMenuOption$();
    const importedOrdersMenuOption$ = this.getImportedMenuOption$();
    const quickOrderEntryMenuOption$ = this.getQuickEntryOrderMenuOption$();
    const quickOrderEntryMenuOption = this.getQuickOrderEntryMenuOption();
    const getImageOrderingMenuOption$ = this.getImageOrderingMenuOption$();

    return combineLatest([
      importOrderMenuOption$,
      importedOrdersMenuOption$,
      quickOrderEntryMenuOption$,
      orderStatusMenuOption$,
      getImageOrderingMenuOption$,
    ]).pipe(
      map(
        ([
          importOrderMenuOption,
          importedOrdersMenuOption,
          quickOrderEntryMenuOpt,
          orderStatusMenuOption,
          imageOrderingMenuOption,
        ]) => {
          const menuOptions: MenuOption[] = [];
          if (myOrdersMenuOption) {
            menuOptions.push(myOrdersMenuOption);
          }
          if (orderStatusMenuOption) {
            menuOptions.push(orderStatusMenuOption);
          }
          if (importOrderMenuOption) {
            menuOptions.push(importOrderMenuOption);
          }
          if (importedOrdersMenuOption) {
            menuOptions.push(importedOrdersMenuOption);
          }
          if (quickOrderEntryMenuOpt) {
            menuOptions.push(quickOrderEntryMenuOption);
          }
          if (imageOrderingMenuOption) {
            menuOptions.push(imageOrderingMenuOption);
          }
          let showLink = menuOptions.length > 0;
          return { menuOptions, showLink } as HeaderLink;
        },
      ),
    );
  }

  getMyBusinessLink$(): Observable<HeaderLink> {
    const allowReporting$ = this.userService.allowReporting$();
    const showBusinessAnalytics$ = this.userService.showBusinessAnalytics$();
    const premierMemberFoodForumOption =
      this.getPremierMemberFoodForumMenuOption();
    const farmersReportMenuOption = this.getFarmersReportMenuOption();
    const glMenuOption$ = this.getGeneralLedgerMenuOption$();
    const inventoryMenuOption$ = this.getInventoryMenuOption$();
    const invoicesMenuOption$ = this.getInvoicesMenuOption$();
    const mlmMenuOption$ = this.getMlmMenuOption$();
    const mppMenuOption$ = this.getMppMenuOption$();
    const moxeMppMenuOption$ = this.getMoxeMppMenuOption$();
    const mySavingsMenuOption$ = this.getMySavingsMenuOption$();
    const newsMenuOption$ = this.getNewsPageMenuOption$();
    const trendview360MenuOption$ = this.getTrendview360MenuOption$();
    const superUserFlag$ = this.panAppState.feature$([
      FEATURES.split_global_alerts_super_user,
    ]);
    const isSuperUser$ = this.userService.isSuperUser$();
    const isGuestUser$ = this.userService.isGuestUser$();
    const userProfiles$ = this.userService.userProfiles$();

    return combineLatest([
      allowReporting$,
      showBusinessAnalytics$,
      glMenuOption$,
      inventoryMenuOption$,
      invoicesMenuOption$,
      mlmMenuOption$,
      mppMenuOption$,
      mySavingsMenuOption$,
      newsMenuOption$,
      trendview360MenuOption$,
      superUserFlag$,
      isSuperUser$,
      isGuestUser$,
      userProfiles$,
      moxeMppMenuOption$,
    ]).pipe(
      map(
        ([
          allowReporting,
          showBusinessAnalytics,
          glMenuOption,
          inventoryMenuOption,
          invoicesMenuOption,
          mlmMenuOption,
          mppMenuOption,
          mySavingsMenuOption,
          newsMenuOption,
          trendview360MenuOption,
          superUserFlag,
          isSuperUser,
          isGuestUser,
          userProfiles,
          moxeMppMenuOption,
        ]) => {
          const menuOptions: MenuOption[] = [];

          if (
            (!superUserFlag || (superUserFlag && !isSuperUser)) && // not superuser and
            showBusinessAnalytics &&
            allowReporting
          ) {
            menuOptions.push(this.getBusinessAnalyticsMenuOption(isGuestUser));
          }
          menuOptions.push(farmersReportMenuOption);
          const isPremierUser = userProfiles?.some(
            profile =>
              profile.key.toLowerCase() === 'axerossouser' &&
              profile.value.toLowerCase() === 'y',
          );

          if (isPremierUser) {
            menuOptions.push(premierMemberFoodForumOption);
          }
          if (glMenuOption) {
            if (superUserFlag) {
              if (!isSuperUser) {
                menuOptions.push(glMenuOption);
              }
            } else {
              menuOptions.push(glMenuOption);
            }
          }
          if (inventoryMenuOption) {
            menuOptions.push(inventoryMenuOption);
          }
          if (invoicesMenuOption) {
            menuOptions.push(invoicesMenuOption);
          }
          if (mlmMenuOption || isGuestUser) {
            menuOptions.push(mlmMenuOption);
          }
          if (mppMenuOption || isGuestUser) {
            if (superUserFlag) {
              if (!isSuperUser) {
                menuOptions.push(mppMenuOption);
              }
            } else {
              menuOptions.push(mppMenuOption);
            }
          }
          if (moxeMppMenuOption) {
            if (superUserFlag) {
              if (!isSuperUser) {
                menuOptions.push(moxeMppMenuOption);
              }
            } else {
              menuOptions.push(moxeMppMenuOption);
            }
          }
          if (mySavingsMenuOption) {
            menuOptions.push(mySavingsMenuOption);
          }
          if (newsMenuOption) {
            menuOptions.push(newsMenuOption);
          }
          if (trendview360MenuOption) {
            menuOptions.push(trendview360MenuOption);
          }
          let showLink = menuOptions.length > 0;
          return { menuOptions, showLink } as HeaderLink;
        },
      ),
    );
  }

  getProductsAndDealsLink$(): Observable<HeaderLink> {
    const browserCategoriesMenuOption$ = this.getBrowseCategoriesMenuOption();
    const dealsForYouMenuOption$ = this.getDealsForYouMenuOption$();
    const allDealsMenuOption$ = this.getAllDealsMenuOption$();
    const suppliesMenuOption$ = this.getSuppliesMenuOption$();
    const showRecipesMenuOption$ = this.getShowRecipesMenuOption$();
    const betterBuysMenuOption$ = this.getBetterBuysMenuOption$();
    const scoopMenuOption = this.getScoopMenuOption();
    const equipmentAndSupplies = this.getEquipmentAndSupplies();
    const serveGoodMenuOption = this.getServeGoodMenuOption();
    const serveYOuMenuOption = this.getServeYou();
    const superUserFlag$ = this.panAppState.feature$([
      FEATURES.split_global_alerts_super_user,
    ]);
    const equipmentAndSuppliesFlag$ = this.panAppState.feature$([
      FEATURES.split_global_equipment_and_supplies,
    ]);
    const isSuperUser$ = this.userService.isSuperUser$();
    const isGuestUser$ = this.userService.isGuestUser$();
    return combineLatest([
      this.userService.showRecipesOnDemand$(),
      this.userService.showValueAddedServices$(),
      this.dealsService.selectDealsProductsExist$(),
      browserCategoriesMenuOption$,
      betterBuysMenuOption$,
      superUserFlag$,
      isSuperUser$,
      isGuestUser$,
      equipmentAndSuppliesFlag$,
      dealsForYouMenuOption$,
      allDealsMenuOption$,
      suppliesMenuOption$,
      showRecipesMenuOption$,
    ]).pipe(
      map(
        ([
          showRecipes,
          showCheckBusiness,
          showAllDeals,
          browserCategoriesMenuOption,
          betterBuysMenuOption,
          superUserFlag,
          isSuperUser,
          isGuestUser,
          equipmentAndSuppliesFlag,
          dealsForYouMenuOption,
          allDealsMenuOption,
          suppliesMenuOption,
          showRecipesMenuOption,
        ]) => {
          const menuOptions: MenuOption[] = [];
          menuOptions.push(browserCategoriesMenuOption, dealsForYouMenuOption);
          if (showAllDeals || isGuestUser) {
            menuOptions.push(allDealsMenuOption);
          }
          menuOptions.push(suppliesMenuOption);

          if (betterBuysMenuOption) {
            menuOptions.push(betterBuysMenuOption);
          }
          menuOptions.push(scoopMenuOption);
          if (equipmentAndSuppliesFlag) {
            menuOptions.push(equipmentAndSupplies);
          }
          menuOptions.push(serveGoodMenuOption);
          menuOptions.push(serveYOuMenuOption);

          // // Hide Check Business Tools Url from header - Leave commented
          // if (showCheckBusiness) {
          //   const checkMenuOption = this.getCheckMenuOption();
          //   menuOptions.push(checkMenuOption);
          // }

          if (showRecipes) {
            if (superUserFlag) {
              if (!isSuperUser) {
                menuOptions.push(showRecipesMenuOption);
              }
            } else {
              menuOptions.push(showRecipesMenuOption);
            }
          }

          return {
            showLink: true,
            menuOptions,
          } as HeaderLink;
        },
      ),
    );
  }

  getBrowseCategoriesMenuOption(): Observable<MenuOption> {
    return combineLatest([
      this.panAppState.feature$([FEATURES.split_global_search_browse]),
      this.userService.isGuestUser$(),
    ]).pipe(
      map(([featureFlag, isGuestUser]) => {
        const path =
          featureFlag || isGuestUser
            ? PATHS.SEARCH_BROWSE
            : PATHS.SEARCH_CATALOG;
        return {
          label:
            featureFlag || isGuestUser
              ? HeaderLinkLabelEnum.guestBrowseProducts
              : HeaderLinkLabelEnum.browseCategories,
          external: false,
          disabled: false,
          handler: () => {
            this.navigationHelperService.routeTo(path);
          },
        };
      }),
    );
  }

  private getDealsForYouMenuOption$(): Observable<MenuOption> {
    return this.userService.isGuestUser$().pipe(
      map(isGuestUser => {
        return {
          label: HeaderLinkLabelEnum.dealsForYou,
          external: false,
          disabled: isGuestUser,
          handler: () => {
            this.navigationHelperService.routeTo(PATHS.DEALS);
          },
        };
      }),
    );
  }

  private getAllDealsMenuOption$(): Observable<MenuOption> {
    return this.userService.isGuestUser$().pipe(
      map(isGuestUser => {
        return {
          label: HeaderLinkLabelEnum.allDeals,
          external: false,
          disabled: isGuestUser,
          handler: () => {
            this.dealsTracingService.traceAllAvailableDeals();
            this.navigationHelperService.routeTo(
              PATHS.DEALS.concat('/', PATHS.ALL_DEALS),
            );
          },
        };
      }),
    );
  }

  private getSuppliesMenuOption$(): Observable<MenuOption> {
    return this.userService.isGuestUser$().pipe(
      map(isGuestUser => {
        return {
          label: HeaderLinkLabelEnum.supplies,
          external: false,
          disabled: isGuestUser,
          handler: () => {
            this.searchTracingService.traceWhileSuppliesLast();
            this.navigationHelperService.routeToSearch({
              searchText: SEARCH_PAGES_SEARCH_KEYS.whileSuppliesLast,
            });
          },
        };
      }),
    );
  }

  private getScoopMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.scoop,
      external: false,
      disabled: false,
      handler: () => {
        this.analyticsService.trackClickScoopBanner();
        this.navigationHelperService.routeToSearch({
          searchText: TopSectionSearchText.ALLSCOOP,
        });
      },
    };
  }

  private getEquipmentAndSupplies(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.equipmentAndSupplies,
      external: false,
      disabled: false,
      handler: () => {
        this.analyticsService.trackEquipmentAndSupplies({
          buttonName: 'equipment & supplies:header',
          promoId: 'EandSCoreEssentials',
        });
        this.navigationHelperService.routeToSearch({
          searchText: TopSectionSearchText.EQUIPMENT_AND_SUPPLIES,
        });
      },
    };
  }

  private getServeYou(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.serveYou,
      external: false,
      disabled: false,
      handler: () => {
        this.analyticsService.trackClickServeYouHeader();
        this.navigationHelperService.routeToSearch({
          searchText: TopSectionSearchText.SERVE_YOU,
        });
      },
    };
  }

  private getServeGoodMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.serveGood,
      external: true,
      disabled: false,
      handler: () => {
        this.appService.openUrlInBrowser(environment.serveGoodUrl);
      },
    };
  }

  private getCheckMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.check,
      external: true,
      disabled: false,
      handler: () => {
        this.appService.openUrlInBrowser(environment.checkUrl);
      },
    };
  }

  private getShowRecipesMenuOption$(): Observable<MenuOption> {
    return this.userService.isGuestUser$().pipe(
      map(isGuestUser => {
        return {
          label: HeaderLinkLabelEnum.recipes,
          external: true,
          disabled: isGuestUser,
          handler: () => {
            this.navigateWithClaimTicket(
              environment.recipesUrl,
              HeaderLinkLabelEnum.recipes,
            );
          },
        };
      }),
    );
  }

  private getMyOrdersMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.myOrders,
      external: false,
      disabled: false,
      handler: () => {
        this.navigationHelperService.routeTo(PATHS.MY_ORDERS_VIEW);
      },
    };
  }

  getOrderStatusMenuOption$(): Observable<MenuOption> {
    const isSuperUser$ = this.userService.isSuperUser$();
    return isSuperUser$.pipe(
      map(isSuperUser => {
        let menuOption: MenuOption = undefined;
        if (!isSuperUser) {
          menuOption = this.getOrderStatusMenuOption();
        }
        return menuOption;
      }),
    );
  }

  private getOrderStatusMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.orderStatus,
      external: false,
      disabled: false,
      handler: () => {
        this.navigationHelperService.routeTo(PATHS.MY_ORDERS);
      },
    };
  }

  private getImportOrderMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.importOrder,
      external: false,
      disabled: false,
      handler: () => {},
    };
  }

  private getImageOrderingMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.imageOrdering,
      external: false,
      disabled: false,
      handler: () => {
        this.navigationHelperService.routeTo(PATHS.IMAGE_ORDERING);
      },
    };
  }

  getImageOrderingMenuOption$(): Observable<MenuOption> {
    const imageOrderingFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_order_image_ordering,
    ]);
    return imageOrderingFeatureFlag$.pipe(
      map(imageOrderingFlag => {
        let menuOption: MenuOption = undefined;
        if (imageOrderingFlag) {
          menuOption = this.getImageOrderingMenuOption();
        }
        return menuOption;
      }),
    );
  }

  getImportedMenuOption$(): Observable<MenuOption> {
    const importedOrdersFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_imported_orders,
    ]);
    const superUserImportOrderFlag$ = this.panAppState.feature$([
      FEATURES.split_global_order_super_user_import_order,
    ]);
    const superUser$ = this.userService.isSuperUser$();
    return combineLatest([
      importedOrdersFeatureFlag$,
      superUser$,
      superUserImportOrderFlag$,
    ]).pipe(
      map(([importedFeatureFlag, isSuperUser, superUserImportOrderFlag]) => {
        let menuOption: MenuOption = undefined;
        if (importedFeatureFlag && (!isSuperUser || superUserImportOrderFlag)) {
          menuOption = this.getImportedOrdersMenuOption();
        }
        return menuOption;
      }),
    );
  }

  private getImportedOrdersMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.importedOrders,
      external: false,
      disabled: false,
      handler: () => {
        this.navigationHelperService.routeTo(PATHS.IMPORTED_ORDERS);
      },
    };
  }

  getImportOrderMenuOption$(): Observable<MenuOption> {
    const importOrderFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_import_order,
    ]);
    const superUserImportOrderFlag$ = this.panAppState.feature$([
      FEATURES.split_global_order_super_user_import_order,
    ]);
    const superUser$ = this.userService.isSuperUser$();
    return combineLatest([
      importOrderFeatureFlag$,
      superUser$,
      superUserImportOrderFlag$,
    ]).pipe(
      map(([importOrderFlag, isSuperUser, superUserImportOrderFlag]) => {
        let menuOption: MenuOption = undefined;
        if (importOrderFlag && (!isSuperUser || superUserImportOrderFlag)) {
          menuOption = this.getImportOrderMenuOption();
        }
        return menuOption;
      }),
    );
  }

  getQuickEntryOrderMenuOption$(): Observable<MenuOption> {
    const quickEntryOrderFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_quick_order_entry_page,
    ]);
    return quickEntryOrderFeatureFlag$.pipe(
      map(quickOrderFlag => {
        let menuOption: MenuOption = undefined;
        if (quickOrderFlag) {
          menuOption = this.getQuickOrderEntryMenuOption();
        }
        return menuOption;
      }),
    );
  }

  private getQuickOrderEntryMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.quickOrderEntry,
      external: false,
      disabled: false,
      handler: () => {
        this.navigationHelperService.routeTo(PATHS.QUICK_ORDER_ENTRY);
      },
    };
  }

  getBetterBuysMenuOption$(): Observable<MenuOption> {
    const betterBuysFlag$ = this.betterBuysService.getBetterBuysFlag();
    return betterBuysFlag$.pipe(
      map(betterBuysFlag => {
        let menuOption: MenuOption = undefined;
        if (betterBuysFlag) {
          menuOption = this.getBetterBuysMenuOption();
        }
        return menuOption;
      }),
    );
  }

  private getBetterBuysMenuOption() {
    return {
      label: HeaderLinkLabelEnum.betterBuys,
      external: false,
      disabled: false,
      handler: () => {
        this.navigationHelperService.routeTo(PATHS.BETTER_BUYS);
      },
    };
  }

  public getBusinessAnalyticsMenuOption(disabled: boolean = false): MenuOption {
    let menuOption: MenuOption = {
      label: HeaderLinkLabelEnum.ba,
      external: true,
      disabled,
      handler: () => {
        this.navigateWithClaimTicket(environment.baUrl, HeaderLinkLabelEnum.ba);
      },
    };
    return menuOption;
  }

  getFarmersReportMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.farmersReport,
      external: true,
      disabled: false,
      handler: () => {
        this.appService.openUrlInBrowser(environment.farmersReportUrl);
      },
    };
  }

  getPremierMemberFoodForumMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.premierMemberFoodForum,
      external: true,
      disabled: false,
      handler: () => {
        this.appService.openUrlInBrowser(environment.premierMemberFoodForumUrl);
      },
    };
  }

  getGeneralLedgerMenuOption$(): Observable<MenuOption> {
    const showGL$ = this.userService.showGL$();
    return showGL$.pipe(
      map(showGL => {
        let menuOption: MenuOption = undefined;
        if (showGL) {
          menuOption = this.getGlMenuOption();
        }
        return menuOption;
      }),
    );
  }

  private getGlMenuOption() {
    return {
      label: HeaderLinkLabelEnum.gl,
      external: true,
      disabled: false,
      handler: () => {
        this.navigateWithClaimTicket(environment.glUrl, HeaderLinkLabelEnum.gl);
      },
    };
  }

  getInventoryMenuOption$(): Observable<MenuOption> {
    return this.userService.showMoxeInventory$().pipe(
      map(showMoxeInventory => {
        let menuOption: MenuOption = undefined;
        if (showMoxeInventory) {
          menuOption = {
            label: HeaderLinkLabelEnum.inventory,
            external: false,
            disabled: false,
            handler: () => {
              this.navigationHelperService.routeTo(PATHS.INVENTORY);
            },
          } as MenuOption;
        }
        return menuOption;
      }),
    );
  }

  getInvoicesMenuOption$(): Observable<MenuOption> {
    const userPaymentsLinkFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_user_payments_link,
    ]);
    const user$ = this.panAppState.user$;
    const showInvoiceAndPayments$ =
      this.userService.showInvoiceAndPaymentsMenu$();
    return combineLatest([
      userPaymentsLinkFeatureFlag$,
      user$,
      showInvoiceAndPayments$,
    ]).pipe(
      map(([paymentsFeatureFlag, user, showInvoicesAndPayments]) => {
        let menuOption: MenuOption = undefined;
        if (showInvoicesAndPayments) {
          menuOption = this.getInvoiceMenuOption(
            paymentsFeatureFlag,
            user,
            showInvoicesAndPayments,
          );
        }
        return menuOption;
      }),
    );
  }

  private getInvoiceMenuOption(
    paymentsFeatureFlag: boolean,
    user: UserState,
    showInvoicesAndPayments: string,
  ) {
    if (paymentsFeatureFlag && user?.userId) {
      return {
        label: showInvoicesAndPayments,
        external: false,
        disabled: false,
        handler: () => {
          this.navigationHelperService.routeTo(PATHS.PAYMENTS);
        },
      };
    } else {
      return {
        label: HeaderLinkLabelEnum.invoicesAndPayments,
        external: true,
        disabled: false,
        handler: () => {
          this.navigateWithClaimTicket(
            environment.paymentsUrl,
            HeaderLinkLabelEnum.invoicesAndPayments,
          );
        },
      };
    }
  }

  getMlmMenuOption$(): Observable<MenuOption> {
    const showMlm$ = this.userService.showMLM$();
    const isGuestUser$ = this.userService.isGuestUser$();
    return combineLatest([showMlm$, isGuestUser$]).pipe(
      map(([showMlm, isGuestUser]) => {
        let menuOption: MenuOption = undefined;
        if (showMlm || isGuestUser) {
          menuOption = this.getMlmMenuOption(isGuestUser);
        }
        return menuOption;
      }),
    );
  }

  private getMlmMenuOption(isGuestUser: boolean): MenuOption {
    return {
      label: HeaderLinkLabelEnum.mlm,
      external: true,
      disabled: isGuestUser,
      handler: () => {
        this.navigateWithClaimTicket(
          environment.mlmUrl,
          HeaderLinkLabelEnum.mlm,
        );
      },
    };
  }

  getMppMenuOption$(): Observable<MenuOption> {
    const showMPP$ = this.userService.showMPP$();
    const isGuestUser$ = this.userService.isGuestUser$();
    return combineLatest([showMPP$, isGuestUser$]).pipe(
      map(([showMpp, isGuestUser]) => {
        let menuOption: MenuOption = undefined;
        if (showMpp || isGuestUser) {
          menuOption = this.getMppMenuOption(isGuestUser);
        }
        return menuOption;
      }),
    );
  }

  private getMppMenuOption(isGuestUser: boolean): MenuOption {
    return {
      label: HeaderLinkLabelEnum.mpp,
      external: true,
      disabled: isGuestUser,
      handler: () => {
        this.navigateWithClaimTicket(
          environment.mppUrl,
          HeaderLinkLabelEnum.mpp,
        );
      },
    };
  }

  getMoxeMppMenuOption$(): Observable<MenuOption> {
    const showMPPMoxe$ = this.userService.showMPPMoxe$();
    return combineLatest([showMPPMoxe$]).pipe(
      map(([showMPPMoxe]) => {
        let menuOption: MenuOption = undefined;
        if (showMPPMoxe) {
          menuOption = this.getMoxeMppMenuOption();
        }
        return menuOption;
      }),
    );
  }

  private getMoxeMppMenuOption(): MenuOption {
    return {
      label: HeaderLinkLabelEnum.moxeMpp,
      external: false,
      disabled: false,
      handler: () => {
        this.navigationHelperService.routeTo(PATHS.MPP);
      },
    };
  }

  getTrendview360MenuOption$(): Observable<MenuOption> {
    const trendviewFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_alerts_trendview,
    ]);
    const showTv360$ = this.userService.showTv360$();
    return combineLatest([trendviewFeatureFlag$, showTv360$]).pipe(
      map(([trendviewFeatureFlag, showTv360]) => {
        let menuOption: MenuOption = undefined;
        if (trendviewFeatureFlag && showTv360) {
          menuOption = this.getTv360MenuOption();
        }
        return menuOption;
      }),
    );
  }

  private getTv360MenuOption() {
    return {
      label: HeaderLinkLabelEnum.tv360,
      external: true,
      disabled: false,
      handler: () => {
        this.navigateWithClaimTicket(
          environment.tv360Url,
          HeaderLinkLabelEnum.tv360,
        );
      },
    };
  }

  getNewsPageMenuOption$(): Observable<MenuOption> {
    const userCustomizations$ = this.userService.userCustomizations$();
    const userCustomizationsFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_user_customizations,
    ]);
    return combineLatest([
      userCustomizations$,
      userCustomizationsFeatureFlag$,
    ]).pipe(
      map(([customizations, customizationsFeatureFlag]) => {
        let menuOption: MenuOption = undefined;
        let label = '';
        let route;

        customizations?.properties?.forEach(customization => {
          if (customization.name === CustomizationOptions.newsMenu) {
            label = customization.value;
          }
          if (customization.name === CustomizationOptions.newsMenuUrl) {
            route = customization.value;
          }
        });

        if (route?.length && label?.length && customizationsFeatureFlag) {
          menuOption = this.getNewsPageMenuOption(label, route);
        }
        return menuOption;
      }),
    );
  }

  private getNewsPageMenuOption(label: string, route: any) {
    return {
      label,
      external: true,
      disabled: false,
      handler: () => {
        window.open(route, '_blank');
      },
    };
  }

  getMySavingsMenuOption$(): Observable<MenuOption> {
    return this.userService.isGuestUser$().pipe(
      map(isGuestUser => {
        let menuOption: MenuOption | undefined;
        menuOption = this.getMySavingsMenuOption(isGuestUser);
        return menuOption;
      }),
    );
  }

  private getMySavingsMenuOption(isGuestUser: boolean) {
    return {
      label: HeaderLinkLabelEnum.mySavings,
      external: true,
      disabled: isGuestUser,
      handler: () => {
        this.navigateWithClaimTicket(
          environment.mySavingsUrl,
          HeaderLinkLabelEnum.mySavings,
        );
      },
    };
  }

  navigateWithClaimTicket(url: string, destinationName: string) {
    this.claimTicketService.getClaimTicket()?.subscribe(
      res => {
        if (res?.claimTicketValue) {
          url = url + res?.claimTicketValue;
          this.appService.openUrlInBrowser(url);
        } else {
          this.popoverHelperService.presentPopover(
            this.popoverController,
            ErrorPopoverComponent,
            `Unable to take you to ${this.translateService.instant(
              destinationName,
            )} at this time. Please try again later.`,
          );
        }
      },
      () => {
        this.popoverHelperService.presentPopover(
          this.popoverController,
          ErrorPopoverComponent,
          `Unable to take you to ${this.translateService.instant(
            destinationName,
          )} at this time. Please try again later.`,
        );
      },
    );
  }
}
