<ion-header
  class="ion-no-border"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-mobile'
  "
>
  <div class="search-modal-toolbar">
    <ion-label
      id="search-all-usfoods-toggle"
      data-cy="search-all-us-foods-label"
      class="ion-text-wrap"
    >
      {{ 'i18n.search.searchCatalogPage.searchAllUSFoods' | translate }}
    </ion-label>
    <div aria-labelledby="search-all-usfoods-toggle">
      <app-toggle-new-search
        *appShowSplit="[toogleNewSearchFeatureFlag]"
      ></app-toggle-new-search>
    </div>
  </div>
</ion-header>

<ion-content
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-mobile'
  "
>
  <ion-searchbar
    id="header-searchbar"
    data-cy="header-searchbar-input"
    class="search"
    enterkeyhint="search"
    mode="md"
    [debounce]="typeaheadDebounce"
    placeholder="{{ 'i18n.header.searchProducts' | translate }}"
    (keydown.enter)="executeSearch($event)"
    (ionInput)="handleInputChange($event)"
  ></ion-searchbar>
  <div
    *ngIf="
      typeaheadVisible && !typeaheadNoResults && !isNewSearchEnabled;
      else newTypeahead
    "
    class="typeahead"
  >
    <section *ngFor="let group of customTypeAheadGroup">
      <h2 [attr.data-cy]="'group-' + group.name">{{ group.name }}</h2>
      <ul *ngFor="let item of group.items; let i = index">
        <li
          (click)="handleItemClick(item, group.name, i + 1)"
          [attr.data-cy]="'click-item-' + item.description"
          [class.active]="item.isActive"
          class="active"
          *ngIf="i < 5"
        >
          {{ item.description }}
        </li>
      </ul>
    </section>
  </div>
</ion-content>

<ng-template #newTypeahead>
  <app-new-search-typeahead
    *ngIf="typeaheadVisible"
    [searchCatalog]="searchbar"
    (isTypeaheadVisible)="onTypeaheadVisible($event)"
  ></app-new-search-typeahead>
</ng-template>
