import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { PanAppState, PlatformEnum } from '@panamax/app-state';
import { CustomerSummary } from '@usf/customer-types/customer';
import { CustomerSelectionOption } from '@app/customer/models/customer-selection-option.model';
import { CustomerSelectionVM } from '@app/customer/models/customer-selection-vm.model';
import { CustomerService } from '@app/customer/services/customer.service';
import { DepartmentSummary } from '@usf/customer-types/department';
import { combineLatest, EMPTY, map, Observable, filter, mergeMap } from 'rxjs';
import { FEATURES } from '@shared/constants/splitio-features';
import {
  CustomerKey,
  UserPreferencesV2,
} from '@usf/user-types/user-preferencev2';
import { take } from 'rxjs/operators';
import { UserService } from '@app/user/services';

@Component({
  selector: 'app-super-user-customers-modal',
  templateUrl: './super-user-customers-modal.component.html',
  styleUrls: ['./super-user-customers-modal.component.scss'],
})
export class SuperUserCustomersModalComponent implements ViewWillEnter, OnInit {
  readonly platformEnum = PlatformEnum;

  @Input() platform: PlatformEnum;
  @Input() favoriteFlag: boolean;

  @Output()
  isSuperUserCustomersModalOpenEmitter = new EventEmitter<boolean>();
  @Output()
  superUserCustomerSelectedEmitter =
    new EventEmitter<CustomerSelectionOption>();
  @Output()
  superUserCustomerFavoriteEmitter = new EventEmitter<{
    customer: CustomerSelectionOption;
    isFavorite: boolean;
  }>();

  superUserCustomersModalVM$: Observable<CustomerSelectionVM>;
  favoritesVm$: Observable<CustomerSelectionVM>; //VM for displaying only the favorites list (on modal open)
  selectedCustomer: CustomerSelectionOption;
  searchKey: string;
  currentPage = 1;

  superUserFavoriteCustomerFeatureFlag$: Observable<boolean>;
  showCustomersDataLoadingSpinner$ =
    this.customerService.getCustomersDataLoadingSpinner$();

  constructor(
    protected customerService: CustomerService,
    readonly panAppState: PanAppState,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.superUserFavoriteCustomerFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_super_user_favorite_customer,
    ]);
    this.loadFavoritesVm();
  }

  ionViewWillEnter() {
    this.resetModalData();
  }

  resetModalData() {
    this.searchKey = '';
    this.selectedCustomer = undefined;
    this.resetSearchResults();
  }

  resetSearchResults() {
    this.customerService.setFilteredCustomerSummaries([]);
    this.changePage(1);
  }

  search(event: any) {
    this.resetSearchResults();
    let searchKey = event?.target?.value?.trim();
    this.searchKey = searchKey;
    if (this.searchKey.length === 0) {
      this.loadFavoritesVm();
    }
    if (searchKey && searchKey.length >= 3) {
      this.getSuperUserCustomerSelectionVM$();
    }
  }

  getSuperUserCustomerSelectionVM$(): void {
    this.customerService.setShowCustomersDataLoadingSpinner(true);
    this.customerService.searchCustomers(this.searchKey);
    this.superUserCustomersModalVM$ = combineLatest([
      this.customerService.filteredCustomerSummaries$,
      this.userService.getUserPreferencesV2(),
    ]).pipe(
      map(([customerSummaries, userPreferences]) => {
        return this.buildSuperUserCustomerSelectionVM(
          customerSummaries,
          userPreferences?.favoriteCustomers,
        );
      }),
    );
  }

  loadFavoritesVm(): void {
    this.customerService.retrieveCustomerFavorites();
    this.customerService.setShowCustomersDataLoadingSpinner(true);
    this.favoritesVm$ = combineLatest([
      this.userService.getUserPreferencesV2(),
      this.customerService.getFavorites$(),
      this.panAppState.customer$,
    ]).pipe(
      filter(
        ([userPreferences, customerSummaries, currentCustomer]) =>
          !!userPreferences?.favoriteCustomers?.length &&
          !!customerSummaries?.length,
      ),
      map(([userPreferences, customerSummaries, currentCustomer]) => {
        const customerList = customerSummaries.filter(
          custSummary =>
            custSummary?.customerNumber !== currentCustomer?.customerNumber,
        );
        return this.buildSuperUserCustomerSelectionVM(
          customerList,
          userPreferences?.favoriteCustomers,
        );
      }),
    );
  }

  buildSuperUserCustomerSelectionVM(
    customerSummaries: CustomerSummary[],
    favoriteCustomers: CustomerKey[],
  ): CustomerSelectionVM {
    const customerSelectionOptions: CustomerSelectionOption[] = [];

    customerSummaries.forEach(customer => {
      const isFavorite = !!favoriteCustomers.find(
        customerKey =>
          customer.customerNumber === customerKey.customerNumber &&
          customer.divisionNumber === customerKey.divisionNumber,
      );

      this.buildCustomersList(customerSelectionOptions, customer, isFavorite);
      this.buildDepartmentsList(
        customerSelectionOptions,
        customer.customerNumber,
        customer.departments,
      );
    });
    const vm: CustomerSelectionVM = {
      customerSelectionOptions,
    };
    return vm;
  }

  buildCustomersList(
    customerSelectionOptions: CustomerSelectionOption[],
    customer: CustomerSummary,
    isFavorite: boolean,
  ): void {
    const customerName = customer.customerName;
    const state =
      customer.state !== null && customer.state !== undefined
        ? customer.state.toUpperCase()
        : '';
    const city =
      customer.city !== null && customer.city !== undefined
        ? customer.city
            .split(' ')
            .filter(word => word.length > 0)
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ')
        : '';
    const customerInfo: CustomerSelectionOption = {
      title: customerName + ' (' + customer.customerNumber + ')',
      subtitle: state.length
        ? (city.length ? city + ', ' : '') + state
        : city.length
          ? city
          : '',
      firstLetter: customerName.charAt(0) ?? '',
      customerName,
      customerNumber: customer.customerNumber,
      divisionNumber: customer.divisionNumber,
      hasDepartments: customer.departments.length ? true : false,
      departmentNumber: '0',
      isDepartment: false,
      isFavorite,
    };
    customerSelectionOptions.push(customerInfo);
  }

  buildDepartmentsList(
    customerSelectionOptions: CustomerSelectionOption[],
    customerNumber: number,
    departments: DepartmentSummary[],
  ): void {
    departments?.forEach(department => {
      const departmentInfo: CustomerSelectionOption = {
        title:
          department.departmentName + ' (' + department.departmentNumber + ')',
        customerNumber,
        divisionNumber: department.divisionNumber,
        departmentNumber: department.departmentNumber,
        isDepartment: true,
      };
      customerSelectionOptions.push(departmentInfo);
    });
  }

  changePage(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  handleDismiss() {
    this.isSuperUserCustomersModalOpenEmitter.emit(false);
  }

  handleSuperUserCustomerSelected() {
    this.superUserCustomerSelectedEmitter.emit(this.selectedCustomer);
  }

  toggleIsFavorite(event: any, customer: CustomerSelectionOption): void {
    // Prevents the click event from bubbling up to the parent
    event.stopPropagation();

    const customerKey: CustomerKey = {
      divisionNumber: customer.divisionNumber,
      customerNumber: customer.customerNumber,
    };

    let isFavorite: boolean;

    const favoriteLimit = 10;

    combineLatest([
      this.userService.getUserPreferencesV2(),
      this.userService.getUserPreferencesV2IsLoading(),
    ])
      .pipe(
        take(1),
        filter(([_preferences, loadingFlag]) => loadingFlag === false),
        mergeMap(([preferences, loadingFlag]) => {
          if (!preferences) {
            return EMPTY;
          }

          isFavorite = preferences.favoriteCustomers.some(
            favorite =>
              favorite.divisionNumber === customerKey.divisionNumber &&
              favorite.customerNumber === customerKey.customerNumber,
          );

          if (
            preferences.favoriteCustomers.length >= favoriteLimit &&
            !isFavorite
          ) {
            return EMPTY;
          }

          const updatedPreferences = isFavorite
            ? this.removeCustomerFromPreferences(customerKey, preferences)
            : this.addCustomerToPreferences(customerKey, preferences);

          return this.userService
            .updateUserPreferencesV2(updatedPreferences)
            .pipe(map(() => ({ customer, isFavorite: !isFavorite })));
        }),
      )
      .subscribe({
        next: updatedData => {
          this.superUserCustomerFavoriteEmitter.emit(updatedData);
        },
      });
  }

  private addCustomerToPreferences(
    customerKey: CustomerKey,
    preferences: UserPreferencesV2,
  ): UserPreferencesV2 {
    const alreadyFavorite = preferences.favoriteCustomers.some(
      favoriteCustomer =>
        favoriteCustomer.divisionNumber === customerKey.divisionNumber &&
        favoriteCustomer.customerNumber === customerKey.customerNumber,
    );

    if (alreadyFavorite) {
      return preferences;
    }

    return {
      ...preferences,
      favoriteCustomers: [...preferences.favoriteCustomers, customerKey],
    };
  }

  private removeCustomerFromPreferences(
    customerKey: CustomerKey,
    preferences: UserPreferencesV2,
  ): UserPreferencesV2 {
    return {
      ...preferences,
      favoriteCustomers: preferences.favoriteCustomers.filter(
        favoriteCustomer =>
          favoriteCustomer.divisionNumber !== customerKey.divisionNumber ||
          favoriteCustomer.customerNumber !== customerKey.customerNumber,
      ),
    };
  }

  getFavoritesCount(
    customerSelectionOptions: CustomerSelectionOption[],
  ): number {
    const custNumbers = new Set(
      customerSelectionOptions.map(cust => cust.customerNumber),
    );
    return custNumbers.size;
  }
}
